/* NewsFooter.css */

.news-footer {
    background-color: #757272;
    padding: 40px 0;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 40px;
    color: white;
  }
  
  .footer-section h3 {
    margin-bottom: 20px;
  }
  
  .social-icons a {
    color:white;
    font-size: 24px;
    margin-right: 10px;
  }
  
  .social-icons a:hover {
    color: #007bff;
  }
  