.search{
    display: flex;
    align-items: center;
    width: 100%;
    height: 10vh;
    justify-content: center;
    padding: 20px;
    }
.search-button{
    font-size: 15px;
    background-color: rgb(126, 125, 125);
    border-radius: 8px;
}
.search-container
 {
                         
 }
#search-input{
    width: 80%;
    height: 30px;
    padding: 10px;
    font-size: 20px;
    border-radius:20px;
}
