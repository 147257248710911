/* CSS for footer */
.footer {
    margin-top: 20px;
    background-color: #333;
    color: white;
    padding: 40px 0;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-column {
    width: 30%;
    margin-bottom: 20px;
}

.footer-column h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.footer-column p {
    margin: 0;
}

.social-icons {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.social-icons li {
    display: inline-block;
    margin-right: 10px;
    font-size: 15px;
}

.social-icons li:last-child {
    margin-right: 0;
}

.social-icons img {
    width: 30px;
    height: 30px;
}
p{
    font-size: 15px;
}
h3{
    font-size: 25px;
}
