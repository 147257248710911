/* NewsCard.css */

.news-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .news-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ccc;
  }
  
  .news-content {
    padding: 20px;
  }
  
  .news-title {
    margin-top: 0;
  }
  
  .news-description {
    margin-bottom: 0;
  }
  #link{
    font-size: 15px;
    text-align: center;
    padding: 10px;
    margin-left: 40%;
  }
  #card{
    -webkit-box-shadow: 10px 40px 82px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 10px 40px 82px 0px rgba(0,0,0,0.4);
    box-shadow: 10px 40px 82px 0px rgba(0,0,0,0.4);
  }
  #title{
    font-size: 40px;
    font-weight: 1000;
    text-align: center;
    margin-top: 5%;
    z-index: 1;

  }
 