#carouselExampleSlidesOnly{
    margin-top: 0px;
    height: 70vh;
    width: 100%;
   overflow: hidden;
   background: rgb(13,169,185);
background: radial-gradient(circle, rgba(13,169,185,1) 3%, rgba(7,38,55,1) 100%, rgba(238,174,202,1) 100%);
}
#pic{
    width: 100%;
    height: 70vh;
}
#btn{
    font-size: 20px;
    background-color: orangered;
}
.heading{
    font-size: 30px;
    font-weight: 1000;
     
}
.discription{
    font-size: 20px;
}