/* CSS for card */
.card{
    margin-top: 20px;
   
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
}
 .item {
    width: 400px;
    height: 400px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}

.card-image {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 20px;
}

.card-title {
    margin: 0;
    font-size: 2.5rem;
}

.card-text {
    margin-top: 10px;
    font-size: 15px;
}

.card-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
}

.card-link:hover {
    background-color: #0056b3;
}
