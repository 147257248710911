#nav{
    background: rgb(13,169,185);
background: radial-gradient(circle, rgba(13,169,185,1) 3%, rgba(7,38,55,1) 100%, rgba(238,174,202,1) 100%);
position: sticky;
top: 0px;  
z-index: 1;   
}
    #navbarNav ul > li > a{
        color: white;
    }
    #head #f{
       font-size: 30px; 
       color: red;
       font-weight: 1000;
    }
    #head #s{
        font-size: 15px; 
        color: rgb(241, 226, 226);
        font-weight: 500;
     }
     #head #t{
        font-size: 30px; 
        color: orange;
        font-weight: 1000;
     }