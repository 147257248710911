.find{
    position: fixed;
    top: 10%;
   
    height: 100vh;
    width: 100%;
    background-color: white;
    z-index: 3;
    overflow-y: scroll;
    
}
.find img{
    width: 50px;
    height:50px;
   
    margin-left: 85%;
}