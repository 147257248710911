nav {
   
    overflow: hidden;
}

nav ul {
    list-style-type: none;
    margin-left:25% ;
    padding: 0;
}

nav li {
    float: left;
}

nav li {
    display: block;
  
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
}

